<script>
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';

export default {
    replace:false,
    computed:{
        startPosition(){
            if(window.outerHeight < 1000){
                return "top bottom-=100px";
            }
            return "top center+=250px";
        }
    },
    mounted(){
        if(this.$refs.h){
            let childSplit = new SplitText(this.$refs.h, {type:'lines', tag:'span', linesClass:'split-child'});
            let parentSplit = new SplitText(this.$refs.h, {type:'lines', tag:'span', linesClass:'split-parent'});
            childSplit.lines.forEach(l => {
                l.style.removeProperty('text-align');
            });
            parentSplit.lines.forEach(l => {
                l.style.removeProperty('text-align');
            });
            let tl = gsap.timeline({
                scrollTrigger:{
                    trigger:this.$refs.h.parentElement,
                    start:this.startPosition,
                    once:true
                },
                onComplete(){
                    tl.kill()
                }
            });
            tl.from(childSplit.lines, {
                duration:0.8, yPercent:100, ease:"power4", stagger:0.1,
            })
            if(this.$refs.p){
                tl.from(this.$refs.p, {
                    opacity:0,
                    duration:0.5
                }, 0.3);
            }
        }
    }
}
</script>
