<script>
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';

export default {
    replace:false,
    data(){
        return {

        }
    },
    computed:{
        imageScrollTrigger(){
            return {
                trigger:this.$refs.image.parentElement,
                start:"top center",
                once:true
            }
        },
        startPosition(){
            if(window.outerHeight < 1000){
                return "top bottom-=100px";
            }
            return "top center+=250px";
        },
        imageAnimation(){
            if(window.outerWidth <= 768){
                return {
                    duration:0.6, scale: 0.8, opacity: 0
                }
            }

            return {
                duration:0.6, opacity: 0, x:'-50%'
            }

        }
    },
    mounted(){
        let imageTl = gsap.timeline({
            scrollTrigger:this.imageScrollTrigger,
            onComplete(){
                imageTl.kill()
            }
        });

        imageTl.from(this.$refs.image, this.imageAnimation);

        let tl = gsap.timeline({
            scrollTrigger:{
                trigger:this.$refs.image.nextElementSibling,
                start:this.startPosition,
                once:true
            },
            onComplete(){
                tl.kill()
            }
        });

        if(this.$refs.h){
            let childSplit = new SplitText(this.$refs.h, {type:'lines', tag:'span', linesClass:'split-child'});
            let parentSplit = new SplitText(this.$refs.h, {type:'lines', tag:'span', linesClass:'split-parent'});
            tl.from(childSplit.lines, {
                duration:0.8, yPercent:100, ease:"power4", stagger:0.1
            })
            childSplit.lines.forEach(l => {
                l.style.removeProperty('text-align');
            });
            parentSplit.lines.forEach(l => {
                l.style.removeProperty('text-align');
            });
        } else if(this.$refs.copy){
            tl.from(this.$refs.copy, {
                opacity:0,
                duration:0.5
            }, 0.3);
        }
    }
}
</script>
