import MainNavigation from './Vue/Components/MainNavigation.vue';
import InlineForm from './Vue/Components/InlineForm.vue';
import SlidingHeading from './Vue/Components/SlidingHeading.vue';
import TextAnimate from './Vue/Components/TextAnimate.vue';
import SplitContent from './Vue/Components/SplitContent.vue';
import EventList from './Vue/Components/EventList.vue';

import {initCarousels, killCarousels} from './components/carousel.js';
import {initVideos, killVideos} from './components/video.js';
import { loaderIn, loaderAway } from './components/loader.js';

import VeeValidate from 'vee-validate';
import VueTheMask from 'vue-the-mask';
import * as VueGoogleMaps from "vue2-google-maps";

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import barba from '@barba/core';

library.add(faFacebookF, faInstagram, faTwitter);
dom.watch();

ScrollTrigger.config({
    ignoreMobileResize:true
});
//ScrollTrigger.normalizeScroll(true);

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);

Vue.use(VeeValidate);
Vue.use(VueTheMask);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDBqMRtUiJedMgsZjwpPTOKGfKoW11xbwI"
    }
});


Vue.component('InlineForm', InlineForm);
Vue.component('SlidingHeading', SlidingHeading);
Vue.component('TextAnimate', TextAnimate);
Vue.component('SplitContent', SplitContent);
Vue.component('EventList', EventList);

let pageApp = null;

const init = () => {
    pageApp = new Vue({
        el: document.querySelector('main .app'),
        mounted(){
            initCarousels();
            initVideos();

            if(window.trackPageView){
                window.trackPageView();
            }
            document.querySelector('body').classList.remove('is-transitioning');
            window.dispatchEvent(new Event('resize'));
        }
    });

};

const kill = () => {
    killCarousels();
    killVideos();
    pageApp.$destroy();
}

//basic vue setup
const headerApp = new Vue({
    el: document.querySelector('.header-app'),
    components:{
        MainNavigation
    }
});


if(!document.querySelector('body').classList.contains('search')){
    //barba setup

    barba.hooks.before(() => {
        document.querySelector('body').classList.add('is-transitioning');
    });

    barba.hooks.enter(() => {
        window.scrollTo(0, 0);
    });
    barba.hooks.after(data => {
        //console.log('after load and init')
        init();
        loaderAway(data.next);

    });
    barba.hooks.afterLeave(data => {
        kill();
    });

    barba.init({
        debug:false,
        logLevel:'off',
        timeout: 30000,
        transitions: [{
            async leave({current}) {
                //console.log('transition start');
                await loaderIn(current.container);
            }
        }]
    });
}

window.macInit = () => {
    init();

}


window.macInit();


if ('serviceWorker' in navigator && (window.location.hostname == 'www.clubmacanudo.com')) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('sw.js').then(function(reg){

        }).catch(function(err) {

        });
    })
}
