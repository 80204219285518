<script>
export default {
    replace:false,
    data () {
        return {
            email:false,
            nickname:false
        }
    },
    methods:{
        async submit(e){
            if(e.target.id == 'RegistrationForm_RegistrationForm'){
                e.preventDefault();
                const form = e.target;
                form.querySelector('input[type="submit"]').disabled = true;
                const data = new URLSearchParams(new FormData(form));

                try{
                    const resposne = await fetch(form.action, {
                        method:'post',
                        body:data
                    })
                    const json = await resposne.json();
                    if(json.success){
                        form.style.display = 'none';
                        form.parentElement.parentElement.querySelector('h4').innerText = 'Thanks!';
                        form.parentElement.parentElement.querySelector('p').innerText = 'You\'ve been signed up. Keep an eye on your inbox for the latest from Club Macanudo New York City.';
                    }
                    form.querySelector('input[type="submit"]').disabled = false;
                } catch(exception){
                    form.querySelector('input[type="submit"]').disabled = false;
                }
            }

        }
    }
}
</script>
