<script>
export default {
    replace:false,
    data () {
        return {
            location:false
        }
    }
}
</script>
